import React, { useContext, useEffect, useState } from 'react'
import { AppLayout, B, Paragraph } from '../../facets'
import { GameStateContext } from '../../contexts'
import { Button, Row } from '../../facets'
import { Trans } from '@lingui/macro'
import { PageIntro } from '../../facets/PageIntro'
import styled from 'styled-components'
import { INTERESTS } from '../../util.ts/interests'
import { i18n } from '@lingui/core'
import { ScoresChart } from '../../controls'

const ManualWrapper = styled.div`
  text-align: center;

  > p {
    margin-top: 1rem;
  }

  > b {
    display: block;
    margin-top: 3rem;
  }

  > img {
    margin-top: 2rem;
  }
`
const ParameterCard = styled.div`
  background: #ffffff;
  height: 50px;
  display: flex;
  border-radius: 3px;
  justify-content: center;
  margin: 1rem 0px;
  > div {
    display: flex;
    width: 92%;
  }
  div > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 36px;
    flex-grow: 1;
  }
  div > div:nth-child(2) {
    display: flex;
    flex-grow: 10;
    margin-left: -36px;
    max-width: unset;
    justify-content: center;
  }
`

const ParameterCardWrapper = styled.div`
  margin: 3rem 0px;
`

export interface ManualPresenterProps {
  showBackToRoleSelection: boolean
}

export const ManualPresenter = () => {
  const { dispatch } = useContext(GameStateContext)
  const locale = i18n.locale as 'en' | 'lo'

  const [scoreChartValues, setScoreChartValues] = useState({
    '1': 0.32364798045341636,
    '2': 0.5225189416569219,
    '3': 0.671535673055786,
    '4': 0.6182926507040811,
    '5': 0.5917060829758446,
  })

  useEffect(() => {
    // add timeout here if you want to animate it (mw 09.11.21)
    setScoreChartValues({
      '1': 0.11624550249702625,
      '2': 0.38897758282779116,
      '3': 0.8662787479714999,
      '4': 0.5578219768886811,
      '5': 0.63301712369696743,
    })
  }, [])
  return (
    <AppLayout headerVariant="manualOpen">
      <ManualWrapper>
        <PageIntro>
          <B color="#B5B7BF">
            <Trans id="manual.intro.title">Spielanleitung</Trans>
          </B>
          <Paragraph>
            <Trans id="manual.intro.desc">
              Pursue your interests while taking the whole coffee sector into
              account
            </Trans>
          </Paragraph>
        </PageIntro>
        <B>
          <Trans id="manual.council.title">The council and its members</Trans>
        </B>
        <Paragraph>
          <Trans id="manual.council.description">
            To promote sustainable development in the regional coffee sector,
            the Lao Government has established a district council to steer
            coffee production in the region. The council consists of ten
            different members, each representing a key actor of the coffee value
            chain. Ideally, each member is represented in the game by one player
            or a small group of players.
          </Trans>
        </Paragraph>
        <img
          src={process.env.PUBLIC_URL + '/images/instruction-roles.svg'}
          alt="Manual roles"
        ></img>
        <Paragraph>
          <Trans id="manual.council.description2">
            Together, you must negotiate and take decisions about the coffee
            sector in Laos.
          </Trans>
        </Paragraph>
        <B>
          <Trans id="manual.parameters.and.interests.title">
            Parameters and interests
          </Trans>
        </B>
        <Paragraph>
          <Trans id="manual.parameters.and.interests.description">
            During the game, you must find a balance between different
            parameters that characterize various dimensions of the Lao coffee
            sector:
          </Trans>
        </Paragraph>
        <ParameterCardWrapper>
          {INTERESTS.map((interest) => {
            return (
              <ParameterCard key={interest.id}>
                <div>
                  <div>
                    <img
                      height="36px"
                      src={`data:image/svg+xml;base64,${btoa(interest.icon)}`}
                      alt={`Avatar ${interest.name[locale]}`}
                    ></img>
                  </div>
                  <div>
                    <B>{interest.name[locale]}</B>
                  </div>
                </div>
              </ParameterCard>
            )
          })}
        </ParameterCardWrapper>
        <Paragraph>
          <Trans id="manual.parameters.and.interests.description2">
            Each member is assigned three of these parameters. The particular
            combination of parameters represents the individual interests that
            each member must pursue throughout the game. Each member has one
            main interest and two side interests.
          </Trans>
        </Paragraph>
        <img
          src={process.env.PUBLIC_URL + '/images/instruction-interests.svg'}
          alt="Manual interests"
        ></img>
        <B>
          <Trans id="manual.events.and.decisions.title">
            Events and decisions
          </Trans>
        </B>
        <Paragraph>
          <Trans id="manual.events.and.decisions.description">
            Als Gruppe werdet ihr einer Serie von Entscheidungen ausgesetzt,
            welche jeweils Einfluss auf die unterschiedlichen Parameter nehmen.
            Diskutiert und argumentiert so, dass eure Interessen gestärkt
            werden.
          </Trans>
        </Paragraph>
        <ScoresChart
          value={scoreChartValues}
          strokeColor={{
            '1': 'dark',
            '2': 'dark',
            '3': 'light',
            '4': 'dark',
            '5': 'light',
          }}
          isActive={{
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false,
          }}
          useStrokeColor={true}
        />
        <Paragraph>
          <Trans id="manual.events.and.decisions.description2">
            However, be aware that there might occur events that you cannot
            directly control. In this case, you just can accept their
            consequences
          </Trans>
        </Paragraph>
        <B>
          <Trans id="manual.global.score.title">Global Score</Trans>
        </B>
        <Paragraph>
          <Trans id="manual.global.score.description">
            Fällt die Erfolgsquote eines einzelnen Parameters auf ein
            unzureichendes Minimum ist Game Over und zwar für alle.
          </Trans>
        </Paragraph>
        <img
          src={process.env.PUBLIC_URL + '/images/instruction-score.svg'}
          alt="Manual score"
        ></img>
        <B>
          <Trans id="manual.ranking.title">Ranking</Trans>
        </B>
        <Paragraph>
          <Trans id="manual.ranking.description">
            If the council has been able to keep the coffee sector in balance,
            the member with the highest achieved score in his or her interests
            win.
          </Trans>
        </Paragraph>
      </ManualWrapper>
      <Row marginTop="4rem" justifyContent="center">
        <Button
          onClick={() => dispatch({ type: 'start' })}
          variant={'negative'}
        >
          <Trans id="manual.lets.go">Okay los gehts</Trans>
        </Button>
      </Row>
      <Row marginTop="4rem" marginBottom="4rem" justifyContent="center">
        <Button
          variant={'link'}
          textColor="#1A2038"
          textColorHover="#454545"
          onClick={() => dispatch({ type: 'showSelectPlayers' })}
        >
          <Trans id="manual.back">Back to language selection</Trans>
        </Button>
      </Row>
    </AppLayout>
  )
}
