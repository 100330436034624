import React, { useContext } from 'react'
import { GameStateContext } from '../../contexts'
import { Button, Row, AppLayout, H1, StaticEnH1 } from '../../facets'

import { Trans } from '@lingui/macro'
import { IntroIcon } from '../../facets/icon/Intro'
import styled from 'styled-components'

const SimpleCard = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ebebf0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 15px;
  min-height: 88px;
  > * {
    max-width: 82.5%;
  }
`
const CardColum = styled.div`
  display: flex;
  > div:nth-child(1) {
    margin: 15px 15px 15px 0px !important;
  }
  > div:last-child {
    margin: 15px 0px 15px 15px !important;
  }
  > div {
    width: calc((100% - 30px) / 3);
  }
`

export const IntroPresenter = () => {
  const { dispatch } = useContext(GameStateContext)
  return (
    <AppLayout headerVariant="language">
      <Row justifyContent="center">
        <IntroIcon></IntroIcon>
      </Row>
      <Row marginTop="1.5rem" justifyContent="center">
        <H1 isLao center>
          <Trans id="language.heading.lao">
            ສົດຊື່ນທຸກຄັ້ງເມື່ອໄດ້ກິ່ ນຫອມຂອງກາເຟ
          </Trans>
        </H1>
      </Row>
      <Row justifyContent="center" marginTop="1.5rem">
        <StaticEnH1 center>
          <Trans id="language.heading.english">
            Wake up and smell the coffee
          </Trans>
        </StaticEnH1>
      </Row>
      <Row justifyContent="center" marginTop="1.5rem">
        <Button
          onClick={() => dispatch({ type: 'showSelectPlayers' })}
          variant={'negative'}
        >
          <Trans id="language.start">Start</Trans>
        </Button>
      </Row>
      <br></br>
      <br></br>
      <br></br>
      <Trans id="intro.developed.by">Dieses Spiel wurde entwickelt von</Trans>
      <CardColum>
        <SimpleCard>
          <img
            src={process.env.PUBLIC_URL + '/images/cde-centre.svg'}
            width={58}
            alt=""
          ></img>
        </SimpleCard>
        <SimpleCard>
          <img
            src={process.env.PUBLIC_URL + '/images/nuol.png'}
            width={48}
            alt=""
          ></img>
        </SimpleCard>
        <SimpleCard>
          <img
            src={process.env.PUBLIC_URL + '/images/zw-logo-black.svg'}
            alt=""
          ></img>
        </SimpleCard>
      </CardColum>
      <br></br>
      <br></br>
      <br></br>
      <Trans id="intro.sponsored.by">
        Mit finanzieller Unterstützung durch
      </Trans>
      <CardColum>
        <SimpleCard>
          <img src={process.env.PUBLIC_URL + '/images/r4d.svg'} alt=""></img>
        </SimpleCard>
        <SimpleCard>
          <img src={process.env.PUBLIC_URL + '/images/fnsnf.svg'} alt=""></img>
        </SimpleCard>
        <SimpleCard>
          <img
            src={
              process.env.PUBLIC_URL +
              '/images/swiss-agency-for-development.svg'
            }
            width={95}
            alt=""
          ></img>
        </SimpleCard>
      </CardColum>
    </AppLayout>
  )
}
