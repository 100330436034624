import { DecisionOption, GameEvent, GameState, Role } from '../model'
import {
  getFutureEvents,
  INITIAL_GAME_STATE,
  proceedToNextEvent,
  processDecision,
  setRoles,
} from '../util.ts/gameState'

type ActionShowManual = {
  type: 'showManual'
}
type ActionShowSelectPlayers = {
  type: 'showSelectPlayers'
}
type ActionShowRanking = {
  type: 'showRanking'
}
type ActionCloseRanking = {
  type: 'closeRanking'
}
type ActionSetRoles = {
  type: 'setRoles'
  roles: Role[]
}
type ActionStart = { type: 'start' }
type ActionResolveEvent = {
  type: 'resolveEvent'
  event: GameEvent
  decision: DecisionOption
}
type ActionNextEvent = {
  type: 'nextEvent'
}
type ActionEndGame = {
  type: 'endGame'
}
type ActionShowEventQuestion = {
  type: 'showEventQuestion'
}
type ActionRestart = { type: 'restart' }

export type GameStateActionI =
  | ActionShowManual
  | ActionShowSelectPlayers
  | ActionSetRoles
  | ActionResolveEvent
  | ActionNextEvent
  | ActionRestart
  | ActionStart
  | ActionEndGame
  | ActionShowRanking
  | ActionCloseRanking
  | ActionShowEventQuestion

export type GameStateReducer = (
  prevState: GameState,
  action: GameStateActionI
) => GameState

export const gameStateReducer: GameStateReducer = (
  state: GameState,
  action: GameStateActionI
): GameState => {
  switch (action.type) {
    case 'showManual':
      return {
        ...state,
        screen: 'manual',
      }
    case 'showSelectPlayers':
      return {
        ...state,
        screen: 'selectRoles',
      }
    case 'setRoles':
      return {
        ...setRoles(state, action.roles),
        screen: 'selectRoles',
      }
    case 'start':
      return {
        ...state,
        screen: 'event',
      }
    case 'resolveEvent':
      return {
        ...processDecision(state, action.event, action.decision),
        screen: 'event',
      }
    case 'nextEvent':
      if (!getFutureEvents(state).length) {
        return {
          ...state,
          gameState: state.globalScore <= 0 ? 'lost' : 'won',
          screen: 'ranking',
        }
      }
      return {
        ...proceedToNextEvent(state),
        currentEventDisplayInfo: true,
        screen: 'event',
      }
    case 'endGame':
      return {
        ...state,
        gameState: state.globalScore <= 0 ? 'lost' : 'won',
        screen: 'ranking',
      }
    case 'restart':
      return INITIAL_GAME_STATE
    case 'showRanking':
      return {
        ...state,
        previousScreen: state.screen,
        screen: 'ranking',
      }
    case 'closeRanking':
      return {
        ...state,
        screen: state.previousScreen,
      }
    case 'showEventQuestion':
      return {
        ...state,
        currentEventDisplayInfo: false,
      }
    default:
      return state
  }
}
