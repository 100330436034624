/*eslint-disable*/ module.exports = {
  messages: {
    'language.heading.english': 'Wake Up - the Lao Coffee Game!',
    'language.heading.lao':
      'ປຸກ​ຄວາມ​ຕື່ນ​ເຕັ້ນ​​ຂອງ​ທ່ານ ເມື່ອຫຼິ້ນເກມກາ​ເຟ​ລາວ!',
    'language.select.english': 'English',
    'language.select.lao': 'ລາວ',
    'language.start': 'Start',
    'manual.lets.go': "Let's go!",
    'scenario.error.occurred': 'An unexpected error occured.',
    'event.end-game': 'End game',
    'event.info.ok': 'Ok',
    'event.next-event': 'Next',
    'selectPlayer.intro.title': 'Selection of roles',
    'selectPlayer.intro.desc':
      'Select the roles that will participate in the game. We recommend playing the game with at least 6 players. Ideally, each role is represented.',
    'event.affectedRoles.intro': 'This has special consequences for the',
    'event.intro.consequences.title': 'Consequences',
    'event.intro.event.title': 'Event',
    'event.intro.story.title': 'Story',
    'intro.developed.by': 'This game was developed by',
    'intro.sponsored.by': 'This project was funded by',
    'manual.council.description':
      'The Lao government wants to promote sustainable development in the coffee sector in the south of the country. To this end, it sets up a district council to steer coffee production. The council consists of up to 10 members, each representing a key actor of the coffee value chain. Ideally, each member is represented in the game by one player or a small group of players. Note that the organizations mentioned are fictional.',
    'manual.council.description2':
      'The members negotiate and make decisions about the coffee sector in southern Laos. ',
    'manual.council.title': 'The council and its members',
    'manual.events.and.decisions.description':
      'In each round, the council faces an event. In many cases, but not in all, it has the power to deliberate and to decide – and its decision will affect some or all of the parameters in some way (either positively or negatively). The slide bars will display a positive effect as an increase, and a negative effect as a decrease:',
    'manual.events.and.decisions.description2':
      'Some events are beyond the council’s control – if such an event occurs, you must accept the consequences.',
    'manual.events.and.decisions.title': 'Events and decisions',
    'manual.global.score.description':
      'The global score indicates how well the council is managing the coffee sector as a whole. Every time a parameter falls below 10%, the global score decreases. If the score is below 0 at the end of the game, the council has failed to keep the coffee sector in balance.',
    'manual.global.score.title': 'Global score',
    'manual.intro.desc':
      'Pursue your interests while considering the impacts of your actions on the whole of the coffee sector. ',
    'manual.intro.title': 'How to play',
    'manual.parameters.and.interests.description':
      'During the game, you must find a balance between the various dimensions – the parameters listed below – of the Lao coffee sector:',
    'manual.parameters.and.interests.description2':
      'Each council member is assigned three of these parameters. The combination of parameters represents the individual interests that each member must pursue throughout the game. Each member has one main interest and two side interests.',
    'manual.parameters.and.interests.title': 'Parameters and interests',
    'manual.ranking.description':
      'If the council manages to keep the coffee sector in balance, the member who has achieved the highest score in his or her interests wins.',
    'manual.ranking.title': 'Ranking',
    'ranging.title.gameState.lost':
      'Too bad, the global score is below zero! The council has made some unsustainable decisions and has failed to keep the coffee sector in balance.',
    'ranging.title.gameState.playing': 'Your current scores',
    'ranging.title.gameState.won':
      'Congratulations, the global score is positive! The council has made sustainable decisions and has managed to keep the coffee sector in balance.',
    'ranking.back_to_game': 'Back to game',
    'ranking.intro.title': 'Ranking',
    'ranking.play_again': 'Play again',
    'select.roles.moderator.description':
      'Instructor who leads through the game',
    'select.roles.moderator.title': 'Narrator',
    'manual.back': 'Back to role selection',
    'head.title': 'Coffee game',
    'head.meta.desc': 'A sustainability game about coffee in Laos',
  },
}
