import React, { Dispatch } from 'react'

import { GameEvent, GameState } from '../model'
import { GameStateActionI } from '../reducers/gameStateReducer'

/*
  GameStateContext provides the state of an ongoing game and the means to
  change it via the `dispatch` function to all game components.
*/

interface GameStateContextI {
  gameState: GameState
  event: GameEvent
  eventCount: number
  dispatch: Dispatch<GameStateActionI>
}
export const GameStateContext = React.createContext<GameStateContextI>(
  {} as GameStateContextI
)
