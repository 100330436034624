import React, { useEffect } from 'react'
import styled from 'styled-components'
import { GameHeaderPresenter, GameHeaderVariants } from '../presenters'
import { i18n } from '@lingui/core'
import { RootStyle } from './Typography'
import { Helmet } from 'react-helmet-async'

type BgVariants = 'lightGrey' | 'gray' | 'darkBlue'

interface AppLayoutProps extends GameHeaderVariants {
  children: React.ReactNode
  bgVariant?: BgVariants
}

interface OuterWrapProps {
  bgVariant?: BgVariants
}

export const AppLayout = ({
  children,
  bgVariant,
  headerVariant,
}: AppLayoutProps) => {
  useEffect(() => {
    document.title = i18n._({ id: 'head.title' })
  }, [])

  return (
    <App bgVariant={bgVariant}>
      <Helmet>
        <title>{i18n._({ id: 'head.title' })}</title>
        <meta name="description" content={i18n._({ id: 'head.meta.desc' })} />
      </Helmet>
      {headerVariant && <GameHeaderPresenter headerVariant={headerVariant} />}
      <OuterWrap>{children}</OuterWrap>
      <RootStyle isLao={i18n.locale === 'lo'} />
    </App>
  )
}

interface BodyProps {
  lang?: string
}

interface AppStyleProps {
  bgVariant?: BgVariants
}

const App = styled.div<AppStyleProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  color: ${(props) =>
    props.bgVariant === 'gray' || props.bgVariant === 'darkBlue'
      ? '#FFFFFF'
      : '#1A2038'};
  ${(props) =>
    props.bgVariant === 'gray'
      ? 'background: linear-gradient(180deg, #B9BABD 0%, #888B99 100%);'
      : props.bgVariant === 'darkBlue'
      ? 'background: linear-gradient(180deg, #2A3356 0%, #1A2038 100%);'
      : 'background-color: #F7F7F9;'}
`

// flex: 1 on this elements makes sure that you have an el thats at minimum
// 100vh in height - if you need to make a full-page-layout. don't add wrapper
// here that break this. (mw 18.08.21)
const OuterWrap = styled.div<OuterWrapProps>`
  padding: 45px 10px 10px;
  box-sizing: border-box;
  flex: 1;

  width: 100%;
  max-width: 885px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`
