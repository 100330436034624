import React, { useContext } from 'react'
import styled from 'styled-components'
import { i18n } from '@lingui/core'
import { AppLayout, B, Button, H3, H4 } from '../../facets'
import { Trans } from '@lingui/macro'
import { GameStateContext } from '../../contexts'
import { DotScore } from '../../facets/DotScore'
import GlobalScore from '../../controls/GlobalScore'
import { useScore } from '../../hooks/useScore'

export const RankingPresenter = () => {
  const { gameState, dispatch } = useContext(GameStateContext)
  const { nrOfCoffeeBeans } = useScore()
  const { rolesRanking } = gameState
  const locale = i18n.locale as 'en' | 'lo'

  const displaySubline = () => {
    return (
      <>
        {gameState.gameState === 'playing' && (
          <Trans id="ranging.title.gameState.playing">Your global Score</Trans>
        )}
        {gameState.gameState === 'won' && (
          <Trans id="ranging.title.gameState.won">
            Yes, euer Handeln hat den Markt.. Euer global Score..
          </Trans>
        )}
        {gameState.gameState === 'lost' && (
          <Trans id="ranging.title.gameState.lost">
            Oh je, Ihr habt nicht nachhaltig.. Euer global Score...
          </Trans>
        )}
      </>
    )
  }

  return (
    <AppLayout
      bgVariant="lightGrey"
      headerVariant={
        gameState.gameState === 'playing' ? 'rankingOpen' : undefined
      }
    >
      <Page>
        <PageContent>
          <PageIntro>
            <B color="#B5B7BF" center>
              <Trans id="ranking.intro.title">Ranking</Trans>
            </B>
            <H3>{displaySubline()}</H3>
            <GlobalScoreWrap>
              <GlobalScore isRanking nrOfCoffeeBeans={nrOfCoffeeBeans} />
            </GlobalScoreWrap>
          </PageIntro>

          {rolesRanking.map((ranking, i) => (
            <PlayerCard key={`ranking${i}`}>
              <H4 color="#b5b7bf">{ranking.rank}.</H4>
              <PlayerCardImage>
                <img
                  src={`data:image/svg+xml;base64,${btoa(ranking.role.avatar)}`}
                  alt={`Avatar ${ranking.role.name[locale]}`}
                />
              </PlayerCardImage>
              <PlayerCardContent>
                <B>{ranking.role.name[locale]}</B>
                <B color="#FF6740">{ranking.role.jobDesc[locale]}</B>
                <DotScore score={ranking.score} />
              </PlayerCardContent>
              <PlayerCardMeta>
                {ranking.role.interestIcons &&
                  ranking.role.interestIcons.map((icon) => (
                    <img
                      src={`data:image/svg+xml;base64,${btoa(icon)}`}
                      alt={`Interest`}
                    />
                  ))}
              </PlayerCardMeta>
            </PlayerCard>
          ))}
        </PageContent>

        <PageFooter>
          <PageAction>
            {gameState.gameState === 'playing' ? (
              <Button
                variant={'negative'}
                onClick={() => {
                  dispatch({ type: 'closeRanking' })
                }}
              >
                <Trans id="ranking.back_to_game">Back to game</Trans>
              </Button>
            ) : (
              <Button
                variant={'negative'}
                onClick={() => {
                  dispatch({ type: 'restart' })
                }}
              >
                <Trans id="ranking.play_again">Play again</Trans>
              </Button>
            )}
          </PageAction>
          <PageAction>
            {gameState.gameState === 'playing' && (
              <Button
                variant={'link'}
                textColor="#1A2038"
                onClick={() => dispatch({ type: 'endGame' })}
              >
                <Trans id="event.end-game">End Game</Trans>
              </Button>
            )}
          </PageAction>
        </PageFooter>
      </Page>
    </AppLayout>
  )
}
const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PageContent = styled.div``

const PageFooter = styled.div`
  padding-top: 40px;
`

const PageIntro = styled.div`
  text-align: center;
  b,
  h3 {
    display: block;
    margin-bottom: 22px;
  }
`

const PageAction = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
`

const GlobalScoreWrap = styled.div`
  margin-bottom: 22px;
`

const PlayerCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 18px 20px;

  background-color: #ffffff;
  border: 1px solid #ebebf0;
  border-radius: 3px;

  transition: 0.2s background-color, 0.2s border-color;
`

const PlayerCardImage = styled.div`
  padding: 0px 7px;
  img {
    border-radius: 50%;
    background-color: #fff;
  }
`

const PlayerCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const PlayerCardMeta = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    width: 40px;
    margin: 0 -5px;
    border: 3px solid #fff;
    border-radius: 50%;
    position: relative;
    z-index: 40;
  }
  img:last-child,
  img:first-child {
    width: 26px;
    margin: 0px;
    border-width: 0;
    z-index: 10;
  }
`
