import React from 'react'
import styled from 'styled-components'
import { ProgressIndicator, StrokeColor } from '../facets'
import { INTERESTS } from '../util.ts/interests'

interface ScoresChartProps {
  value: { [interestId: string]: number }
  strokeColor: { [interestId: string]: StrokeColor }
  useStrokeColor: boolean
  isActive?: { [interestId: string]: boolean }
  onComplete?: () => void
}

export const ScoresChart = (props: ScoresChartProps) => {
  const { value, strokeColor, isActive, onComplete, useStrokeColor } = props
  const ScoreRow = (
    index: number,
    value: number,
    strokeColor: StrokeColor,
    icon: string,
    isActive?: boolean,
    onComplete?: () => void
  ) => {
    return (
      <ProgressIndicatorWrap key={index}>
        <ProgressIndicator
          onComplete={onComplete}
          strokeColor={strokeColor}
          icon={icon}
          active={isActive}
          value={value}
          useStrokeColor={useStrokeColor}
        />
      </ProgressIndicatorWrap>
    )
  }

  return (
    <>
      {INTERESTS.map((interest, index) =>
        ScoreRow(
          index,
          value[interest.id],
          strokeColor[interest.id],
          interest.icon,
          isActive ? isActive[interest.id] : false,
          onComplete
        )
      )}
    </>
  )
}

const ProgressIndicatorWrap = styled.div`
  margin: 30px 0;
  @media only screen and (max-width: 600px) {
    margin: 25px 0;
  }
`
