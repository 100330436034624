import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { GameStateContext } from '../contexts'
import {
  IconCloseRanking,
  IconInfoClose,
  IconInfoOpen,
  IconOpenRanking,
} from '../facets/icon/Header'
import { messages as en } from '../locales/en/messages'
import { messages as lo } from '../locales/lo/messages'
import GlobalScore from '../controls/GlobalScore'
import { useScore } from '../hooks/useScore'

export type GameHeaderVariants = {
  headerVariant?: 'inGame' | 'rankingOpen' | 'language' | 'manualOpen'
}

interface GameHeaderPresenterProps extends GameHeaderVariants {}

export const GameHeaderPresenter = ({
  headerVariant,
}: GameHeaderPresenterProps) => {
  const { dispatch } = useContext(GameStateContext)
  const { nrOfCoffeeBeans, lastNrOfCoffeeBeans } = useScore()

  const handleOpenRankingClick = () => {
    dispatch({ type: 'showRanking' })
  }
  const handleCloseRankingClick = () => {
    dispatch({ type: 'closeRanking' })
  }
  const handleOpenManualClick = () => {
    dispatch({ type: 'showManual' })
  }
  const handleCloseManualClick = () => {
    dispatch({ type: 'start' })
  }

  const displayInGameHeader = () => {
    return (
      <Header>
        <Left>
          <GlobalScore
            nrOfCoffeeBeans={nrOfCoffeeBeans}
            lastNrOfCoffeeBeans={lastNrOfCoffeeBeans}
          />
        </Left>
        <Right>
          <button
            onClick={() => {
              handleOpenManualClick()
            }}
          >
            <IconInfoOpen />
          </button>
          <button
            onClick={() => {
              handleOpenRankingClick()
            }}
          >
            <IconOpenRanking />
          </button>
        </Right>
      </Header>
    )
  }

  const displayRankingHeader = () => {
    return (
      <Header>
        <Left />
        <Right>
          <button
            onClick={() => {
              handleCloseRankingClick()
            }}
          >
            <IconCloseRanking />
          </button>
        </Right>
      </Header>
    )
  }

  const displayManualHeader = () => {
    return (
      <Header>
        <Left />
        <Right>
          <button
            onClick={() => {
              handleCloseManualClick()
            }}
          >
            <IconInfoClose />
          </button>
        </Right>
      </Header>
    )
  }

  const displayLangaugeHeader = () => {
    return (
      <Header>
        <Left />
        <Right>
          <LanguageLink
            onClick={() => {
              i18n.load('lo', lo)
              i18n.activate('lo')
            }}
            isLao
            isSelected={i18n.locale === 'lo'}
          >
            <Trans id="language.select.lao">ຄົນລາວ</Trans>
          </LanguageLink>
          <LanguageLink
            onClick={() => {
              i18n.load('en', en)
              i18n.activate('en')
            }}
            isSelected={i18n.locale === 'en'}
          >
            <Trans id="language.select.english">English</Trans>
          </LanguageLink>
        </Right>
      </Header>
    )
  }

  switch (headerVariant) {
    case 'inGame':
      return displayInGameHeader()

    case 'rankingOpen':
      return displayRankingHeader()

    case 'language':
      return displayLangaugeHeader()

    case 'manualOpen':
      return displayManualHeader()

    default:
      return null
  }
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  button {
    appearance: none;
    background-color: transparent;
    border: 0;
  }
`

const Left = styled.div``
const Right = styled.div`
  & > button {
    margin-left: 7px;
  }
`

interface LanguageLinkProps {
  isLao?: boolean
  isSelected?: boolean
}

const LanguageLink = styled.div<LanguageLinkProps>`
  display: inline;
  text-transform: uppercase;
  margin-left: 20px;
  cursor: pointer;

  ${(props) =>
    props.isLao
      ? css`
          font-family: 'Phetsarath', sans-serif;
          font-size: 23px;
        `
      : css`
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 19px;
        `}
  ${(props) =>
    props.isSelected &&
    css`
      text-decoration: underline;
    `}
`
