import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from '@lingui/core'
import { GameStateContext, ScenariosContext } from '../contexts'
import { Trans } from '@lingui/macro'
import { Role } from '../model'
import { Row } from './Row'

export const EventAffectedRoles = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)

  const scenario = useContext(ScenariosContext)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!(scenario && event))
    return (
      <>
        <Trans id="scenario.error.occurred">
          Es ist ein Fehler aufgetreten!
        </Trans>
      </>
    )

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  const infoText = event.info[i18n.locale as 'en' | 'lo']

  if (!infoText && gameState.currentEventDisplayInfo) {
    dispatch({ type: 'showEventQuestion' })
  }

  let affectedRoles: Role[] = []
  if (decision)
    affectedRoles = Object.entries(event.roleEffect[decision])
      .filter(([_roleID, value]) => Boolean(value))
      .map(([roleID, _value]) =>
        gameState.roles.find((role) => role.id === roleID)
      )
      .filter(Boolean) as Role[]
  const hasAffectedRoles = affectedRoles.length > 0

  return (
    <>
      {hasAffectedRoles && (
        <Row
          marginBottom={{ mobile: '30px', desktop: '60px' }}
          justifyContent="center"
        >
          <AffectedRolesCard>
            <PlayerCardReel>
              {affectedRoles.map((role) => (
                <PlayerCardImage key={role.id}>
                  <img
                    src={`data:image/svg+xml;base64,${btoa(role.avatarSmall)}`}
                    alt={`Avatar ${role.name}`}
                  />
                </PlayerCardImage>
              ))}
            </PlayerCardReel>
            <AffectedRolesList>
              <Trans id="event.affectedRoles.intro">
                This event particulary affected the following roles:
              </Trans>
              &nbsp;
              {affectedRoles
                .map((role) => `${role.jobDesc[i18n.locale as 'en' | 'lo']}`)
                .join(', ')}
            </AffectedRolesList>
          </AffectedRolesCard>
        </Row>
      )}
    </>
  )
}

const AffectedRolesCard = styled.div`
  width: 100%;
  margin: 33px 0 0 0;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
`
const PlayerCardReel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -46px;
  position: relative;
  top: 12px;
`
const PlayerCardImage = styled.div`
  padding: 0px 7px;
  img {
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    width: 42px;
  }
  & + & {
    margin-left: -32px;
  }
`
const AffectedRolesList = styled.p`
  padding: 16px 24px 24px 24px;
  text-align: center;
`
