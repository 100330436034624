/*eslint-disable*/ module.exports = {
  messages: {
    'language.heading.english': 'Wake Up - the Lao Coffee Game!',
    'language.heading.lao':
      '​ປຸກ​ຄວາມ​ຕື່ນ​ເຕັ້ນ​​ຂອງ​ທ່ານ ເມື່ອຫຼິ້ນເກມກາ​ເຟ​ລາວ!',
    'language.select.english': 'English',
    'language.select.lao': '​ລາວ',
    'language.start': '​ເລີ່ມ​',
    'manual.lets.go': '​ເລີ່ມ​ເລີຍ!',
    'scenario.error.occurred': 'ເກີດຄວາມຜິດພາດຂຶ້ນໂດຍ​ບໍ່​ຮູ້​ສາ​ເຫດ.',
    'event.end-game': 'ຈົບເກມ',
    'event.info.ok': 'ຕົກລົງ',
    'event.next-event': '​ຕໍ່ໄປ',
    'selectPlayer.intro.title': '​ການເລືອກ​ບົດ​ບາດ​ຕ່າງໆ​',
    'selectPlayer.intro.desc':
      'ເລືອກ​ບົດ​ບາດ​ຕ່າງໆ​​ເພື່ອທີ່​ຈະຫຼິ້ນ​ເກ​ມ. ພວກ​ເຮົາ​ຂໍ​ແນະ​ນຳ​ໃຫ້​ມີ​ຜູ້ຫຼິ້ນ​ເກມຢ່າງ​ໜ້ອຍ 6 ຄົນ. ຕາມຫຼັກ​ການ​ແລ້ວ​, ແມ່ນ​ໃຫ້​ມີ​ຜູ້ຫຼິ້ນ​ເກມ​ຄົບ​ຕາມ​ແຕ່​ລະ​ບົດ​ບາດ​ທີ່​ກຳ​ນົດ​ໄວ້. ',
    'event.affectedRoles.intro': '​ສິ່ງນີ້​​ສົ່ງຜົນ​ແບບ​ພິ​ເສດ​​ຕໍ່​ກັບ',
    'event.intro.consequences.title': 'ຜົນທີ່​ຕາມ​ມາ',
    'event.intro.event.title': '​ເຫດ​ການ',
    'event.intro.story.title': 'ເນື້ອ​ໃນ​ຂອງ​ເຫດ​ການ',
    'intro.developed.by': 'ເກມ​​ນີ້​ຖືກ​ພັດ​ທະ​ນາ​ໂດຍ',
    'intro.sponsored.by': 'ຜູ້ສະ​ໜັບ​ສະ​ໜູນ​​ໂຄງ​ການ',
    'manual.council.description':
      'ລັດ​ຖະບານລາວຕ້ອງການສົ່ງເສີມການພັດທະນາແບບຍືນຍົງໃນ​ຂະ​ແໜງກາເຟ​ຢູ່ພາກໃຕ້ຂອງປະເທດລາວ. ​ສະ​ນັ້ນ, ​​ຈຶ່ງ​ໄດ້​ສ້າງ​ຕັ້ງ​​ສະ​ພາ​ເມືອງຂຶ້ນ​ເພື່ອ​​ຂັບ​ເຄື່ອນການ​ຜະລິດ​ກາ​ເຟ. ສະພາປະກອບດ້ວຍ​ສະມາຊິກ​ຈຳ​ນວນ 10 ທ່ານ, ​ເຊິ່ງຕົວແທນ​ແຕ່​ລະ​ທ່ານຈະ​ມີ​ບົດ​ບາດສຳ​ຄັນໃນຕ່ອງໂສ້ມູນຄ່າ​ເພີ່ມ​ຂອງ​ຂະ​ແໜງກາເຟ. ​ຕາມຫຼັກ​ການ​ແລ້ວ, ສະມາຊິກແຕ່ລະທ່ານເປັນຕົວແທນຢູ່ໃນເກມ​ ລວມ​ມີ​ຜູ້ຫຼິ້ນ​ເກມ​ແບບ​ດ່ຽວ ຫລື ແບບ​ເປັນກຸ່ມ​​ນ້ອຍ. ​ໝາຍ​ເຫດ: ບັນ​ດ​າອົງການຈັດຕັ້ງ​​ຕ່າງໆ​ທີ່ຖືກ​ອ້າງ​ເຖິງ​ຢູ່​ໃນ​ເກມ​ເປັນ​ພຽງການ​ສົມ​ມຸດ​ຂຶ້ນ​ເທົ່າ​ນັ້ນ.',
    'manual.council.description2':
      'ບັນດາທ່ານ​ທີ່​ເປັນສະມາຊິກ​ຈະ​ຕ້ອງເຈລະຈາ ແລະ ຕັດສິນໃຈກ່ຽວກັບຂະແໜງກາເຟໃນພາກໃຕ້ຂອງລາວ.',
    'manual.council.title': '​ສະ​ພາ ແລະ ບັນ​ດາ​ສະ​ມາ​ຊິກ​ຂອງ​ສະ​ພາ',
    'manual.events.and.decisions.description':
      'ໃນແຕ່ລະຮອບ, ສະພາ​ຈະປະເຊີນກັບເຫດການໃດ​ໜຶ່ງ. ໃນຫຼາຍໆກໍລະນີ, ແຕ່ບໍ່ແມ່ນທັງຫມົດ, ສະພາ​ມີອໍານາດທີ່​ຈະ​ພິ​ຈາ​ລະ​ນາແລະຕັດສິນໃຈ ເຊິ່ງການຕັດສິນໃຈ​ດັ່ງ​ກ່າວຈະສົ່ງຜົນກະທົບຕໍ່ບາງຕົວຊີ້ບອກໃນ​ທາງ​ໃດ​ໜຶ່ງ(ທັງທາງບວກ ຫຼື ທາງລົບ). ແຖບຂອງ​ຕົວ​ຊີ້ບອກຈະສະແດງຜົນທາງບວກໂດຍ​ການເພີ່ມຂີດຂຶ້ນ ແລະ ຜົນທາງລົບໂດຍ​ການຫຼຸດ​ຂີດລົງ:',
    'manual.events.and.decisions.description2':
      'ບາງເຫດການ​ແມ່ນ​ຢູ່ນອກ​ເໜືອ​ການຄວບຄຸມຂອງສະພາ, ເຊິ່ງຖ້າຫາກ​ເກີດເຫດການດັ່ງກ່າວຂຶ້ນ, ທ່ານ​ຈະຕ້ອງຍອມຮັບຜົນທີ່ຕາມມາ.',
    'manual.events.and.decisions.title': '​ເຫດ​ການ ແລະ ການ​ຕັດ​ສິນ​ໃຈ​ຕ່າງໆ',
    'manual.global.score.description':
      'ຄະ​ແນນ​ລວມ​ໃນ​ເກມ​ຈະ​ຊີ້​​ໃຫ້​ເຫັນ​ວ່າ ​ສະ​ພາ​ຈະ​ສາ​ມາດບໍ​ລິ​ຫານ​ຂະ​ແໜງ​ກາ​ເຟ​​ໂດຍ​ລວມໄດ້​ດີ​​ຄື​ແນວ​ໃດ. ທຸກໆຄັ້ງທີ່​ຕົວ​ຊີ້ບອກຫຼຸດລົງຕໍ່າກວ່າ 10%, ຄະແນນ​ລວມ​ໃນ​ເກມ​ກໍ​ຈະຫຼຸດລົງ​ເຊັ່ນ​ກັນ. ຖ້າຄະແນນຕໍ່າກວ່າ 0 ໃນຕອນທ້າຍຂອງເກມ, ​ມັນ​ສະ​ແດງ​ໃຫ້​ເຫັນ​ວ່າສະພາບໍ່​ສາ​ມາດ​ທີ່​ຈະ​ຮັກ​ສາ​ຄວາມ​ສົມ​ດູນ​ຂ​ອງຂະແຫນງກາເຟ​ໄວ້​ໄດ້.',
    'manual.global.score.title': '​ຄະ​ແນນ​ລວມ​ໃນ​ເກມ',
    'manual.intro.desc':
      'ນອກ​ຈາກ​ຈະ​ປົກ​ປ້ອງ​ຜົນ​ປະ​ໂຫຍດ​ຂອງ​ທ່ານ​ເອງ​ແລ້ວ ກໍ​ຍັງ​ຕ້ອງ​ຄຳ​ນຶງ​ເຖິງ​ຜົນ​ກະ​ທົບ​ຈາກ​ການ​ຕັດ​ສິນ​ໃຈຂອງ​ທ່ານ​ຕໍ່​ກັບພາບ​ລວມ​ຂອງຂະແໜງກາເຟ.',
    'manual.intro.title': '​ຄູ່​ມື​ແນະ​ນຳ​ການຫຼິ້ນ​ເກມ',
    'manual.parameters.and.interests.description':
      'ໃນ​ຂະ​ນະ​ທີ່ຫຼິ້ນ​ເກມ, ທ່ານຕ້ອງຮັກ​ສາຄວາມສົມດູນ​ຂອງ​ດ້ານຕ່າງໆ​ຂອງຂະແຫນງກາເຟລາວ, ເຊິ່ງ​ໝາຍ​ເຖິງ​ບັນ​ຕົວ​ຊີ້ບອກ​ຂ້າງລຸ່ມນີ້:',
    'manual.parameters.and.interests.description2':
      'ສະມາຊິກສະພາແຕ່ລະທ່ານ​ຈະຖືກມອບໝາຍ​ໃຫ້​ຮັບ​ຜິດ​ຊອບ 3 ຕົວຊີ້ບອກ​ເຫຼົ່າ​ນີ້. ການປະສົມປະສານຂອງ​ຕົວ​ຊີ້ບອກສະແດງ​ໃຫ້​ເຫັນເຖິງຜົນປະໂຫຍດສ່ວນບຸກຄົນທີ່ສະມາຊິກແຕ່ລະທ່ານຕ້ອງ​ໄດ້ປະຕິບັດຕາມຕະຫຼອດ​ການຫຼິ້ນເກມ. ສະມາຊິກແຕ່ລະ​ທ່ານ​ຈະ​ມີຜົນປະໂຫຍດຫຼັກ​ດ້ານ​ໜຶ່ງ ແລະ ຜົນປະໂຫຍດພ່ວງ​ອີກສອງດ້ານ.',
    'manual.parameters.and.interests.title': 'ຕົວຊີ້ບອກ​ ແລະ ຜົນ​ປະ​ໂຫຍດ​ຕ່າງໆ',
    'manual.ranking.description':
      'ຖ້າສະພາສາ​ມາດຮັກສາ​ຄວາມ​ສົມ​ດູນ​ຂອງຂະແຫນງກາເຟ, ສະມາຊິກທີ່​ໄດ້​ຮັບ​ຄະ​ແນນ​ສູງ​ສຸດໃນ​ດ້ານ​ທີ່​ຕົນ​ເອງ​ຮັບ​ຜິດ​ຊອບ ຈະ​ເປັນ​ຜູ້ຊະນະ​.',
    'manual.ranking.title': 'ການ​ຈັດ​ລຳ​ດັບ',
    'ranging.title.gameState.lost':
      '​ໂຊກບໍ່ດີ​ເລີຍ, ຄະແນນ​ລວມ​ໃນ​ເກມຕໍ່າກວ່າ 0 ! ສະພາບໍ່​ສາ​ມາດ​ດຳ​ເນີນ​ການຕັດສິນ​ໃຈໄດ້​ອີກ ​ແລະ ບໍ່​ສາມາດ​ຮັກສາຄວາມ​ສົມ​ດູນ​ຂອງ​ຂະ​ແໜງ​ກາ​ເຟ​​ໄດ້.',
    'ranging.title.gameState.playing': '​ຄະ​ແນນ​ປະ​ຈຸ​ບັນ​ຂອງ​ທ່ານ',
    'ranging.title.gameState.won':
      'ຂໍສະແດງຄວາມຍິນດີ, ຄະແນນ​ລວມ​ໃນ​ເກມເປັນບວກ! ສະພາ​ຍັງສາ​ມາດ​ດຳ​ເນີນ​ການຕັດສິນ​ໃຈ​ຕໍ່ໄດ້​ອີກ ​ແລະ ​ຍັງສາມາດ​ຮັກສາຄວາມ​ສົມ​ດູນ​ຂອງ​ຂະ​ແໜງ​ກາ​ເຟ​​ໄດ້.',
    'ranking.back_to_game': '​ກັບ​ຄືນ​ສູ່​ເກມ',
    'ranking.intro.title': '​ການ​ຈັດ​ລຳ​ດັບ',
    'ranking.play_again': 'ຫຼິ້ນ​ເກມ​ອີກ​ຄັ້ງ',
    'select.roles.moderator.description': 'ຜູ້​ໃຫ້​ຄຳ​ແນະ​ນຳໃນ​ການຫຼິ້ນ​ເກມ',
    'select.roles.moderator.title': 'ຜູ້​ນຳ​ພາ​ເກມ',
    'manual.back': 'ກັບ​ຄືນ​​ສູ່​ການ​ເລືອກ​ບົດ​ບາດ',
    'head.title': 'ເກມກາເຟ',
    'head.meta.desc': 'ເກມຄວາມຍືນຍົງກ່ຽວກັບກາເຟໃນລາວ',
  },
}
