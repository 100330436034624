import React from 'react'
import { ScoresChart } from './ScoresChart'

import { GameEvent, GameState } from '../model'
import { isHighlyAffected } from '../util.ts/score'
import { INTERESTS } from '../util.ts/interests'

interface EventScoresChartProps {
  event: GameEvent
  gameState: GameState
  onComplete?: () => void
}

export const EventScoresChart = (props: EventScoresChartProps) => {
  const { event, gameState, onComplete } = props
  const { scores, tendencies } = gameState

  const strokeColor = () => {
    let color = {}
    INTERESTS.forEach((interest) => {
      const sign = Math.sign(tendencies[interest.id])

      let interestColor = 'white'
      if (sign === 1) interestColor = 'light'
      if (sign === -1) interestColor = 'dark'

      color = {
        ...color,
        ...{ [interest.id]: interestColor },
      }
    })
    return color
  }

  const isActive = () => {
    let active = {}
    INTERESTS.forEach((interest) => {
      active = {
        ...active,
        ...{ [interest.id]: isHighlyAffected(event.effect, interest.id) },
      }
    })
    return active
  }

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  return (
    <ScoresChart
      value={scores}
      onComplete={onComplete}
      isActive={isActive()}
      strokeColor={strokeColor()}
      useStrokeColor={typeof decision !== 'undefined'}
    ></ScoresChart>
  )
}
