import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'

import {
  ScenariosContext,
  CurrentScenarioContext,
  GameStateContext,
} from '../contexts'
import { useGameState } from '../hooks'
import {
  EventPresenter,
  GameStatePresenter,
  RankingPresenter,
} from '../presenters'
import { ManualPresenter } from '../presenters/gameScreens/ManualPresenter'
import { Trans } from '@lingui/macro'
import { IntroPresenter } from '../presenters/gameScreens/IntroPresenter'
import { GameScreen } from '../model'
import { SelectRolesPresenter } from '../presenters/gameScreens/SelectRolesPresenter'
import { AppLayout } from '../facets'

type GameScreenMap = {
  [screen in GameScreen]: () => JSX.Element | null
}

export const Scenario = ({ id }: RouteComponentProps<{ id: string }>) => {
  const scenario = useContext(ScenariosContext)
  const [gameState, dispatch] = useGameState()
  const { screen } = gameState

  const event = scenario?.events.find(
    (event) => event.id === gameState.currentEventId
  )

  if (!(scenario && event))
    return (
      <AppLayout>
        <Trans id="scenario.error.occurred">
          Es ist ein Fehler aufgetreten!
        </Trans>
      </AppLayout>
    )
  const eventCount = scenario.events.length

  const gameScreens: GameScreenMap = {
    manual: ManualPresenter,
    intro: IntroPresenter,
    event: EventPresenter,
    selectRoles: SelectRolesPresenter,
    ranking: RankingPresenter,
  }
  const GameScreen = gameScreens[screen]

  return (
    <CurrentScenarioContext.Provider value={scenario}>
      <GameStatePresenter {...{ gameState, event, eventCount }} />
      <GameStateContext.Provider
        value={{ gameState, event, eventCount, dispatch }}
      >
        <GameScreen />
      </GameStateContext.Provider>
      {/*
      <Link to="/">End Game</Link>
        */}
    </CurrentScenarioContext.Provider>
  )
}
