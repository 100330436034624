import React, { useContext, useState } from 'react'
import { i18n } from '@lingui/core'

import { GameStateContext } from '../contexts'
import { CheckBoxButton, Row } from '../facets'
import { DecisionOption } from '../model'

interface EventDecision {}

export const EventDecision = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)
  const [hover, setHover] = useState<string>('')

  const resolveEvent = (decision: DecisionOption) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: 'resolveEvent',
        event,
        decision,
      })
      resolve()
    })
  }

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  if (event.noDecision) {
    return null
  }

  return (
    <Row
      marginBottom={{ mobile: '15px', desktop: '30px' }}
      justifyContent="center"
      flexWrap
    >
      <span
        onMouseEnter={() => setHover('A')}
        onMouseLeave={() => setHover('')}
      >
        <CheckBoxButton
          onClick={() => {
            !decision && resolveEvent(DecisionOption.A)
          }}
          variant={
            decision === 'A'
              ? 'selected'
              : decision === 'B' || hover === 'B'
              ? 'inactive'
              : undefined
          }
        >
          {event.optionA[i18n.locale as 'en' | 'lo']}
        </CheckBoxButton>
      </span>
      <span
        onMouseEnter={() => setHover('B')}
        onMouseLeave={() => setHover('')}
      >
        <CheckBoxButton
          onClick={() => {
            !decision && resolveEvent(DecisionOption.B)
          }}
          variant={
            decision === 'B'
              ? 'selected'
              : decision === 'A' || hover === 'A'
              ? 'inactive'
              : undefined
          }
        >
          {event.optionB[i18n.locale as 'en' | 'lo']}
        </CheckBoxButton>
      </span>
    </Row>
  )
}
