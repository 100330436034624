import React from 'react'
import { Scenario } from '../model'
import { INITIAL_EVENTS } from '../util.ts/gameEvents'

const scenario: Scenario = {
  id: 1,
  name: 'scenarioName',
  events: INITIAL_EVENTS,
}

export const ScenariosContext = React.createContext<null | Scenario>(scenario)

/*
  CurrentScenarioContext provides the current scenario to game components.
*/

export const CurrentScenarioContext = React.createContext<Scenario>(
  {} as Scenario
)
