import React from 'react'
import styled from 'styled-components'

export const DotScore = ({ score }: { score: number }) => {
  const displayScoreDots = () => {
    const scoreDots = []
    for (let i = 0; i <= 5; i++) {
      scoreDots.push(
        <ScoreDotWrap key={i}>
          <ScoreDot />
        </ScoreDotWrap>
      )
    }
    return scoreDots
  }

  return (
    <ScoreWrap>
      <ScoreProgress>
        <ScoreProgressBar width={`${score}%`} />
      </ScoreProgress>
      <ScoreDots>{displayScoreDots()}</ScoreDots>
    </ScoreWrap>
  )
}

const ScoreWrap = styled.div`
  margin: 8px 0 8px 0px;
`

const ScoreProgress = styled.div`
  width: 50px;
  height: 8px;
  background: #ebebf0;
`

// add "transition: width .2s" here if you want to animate it
const ScoreProgressBar = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background-color: #e8c5a3;
`

const ScoreDots = styled.div`
  display: inline-flex;
  margin-top: -8px;
  position: absolute;
`

const ScoreDotWrap = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  overflow: hidden;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
`

const ScoreDot = styled.div`
  width: 8px;
  height: 8px;
  box-shadow: 0 0 0 8px #fff;
  border-radius: 50%;
`
