import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

export type StrokeColor = 'dark' | 'light' | 'white'
const darkStroke =
  'linear-gradient(to right,#8C6B7A 0%, #BF8677 47.57%, #FF7956 96.13%);'
const lightStroke = 'linear-gradient(90deg, #A8D76E 0.6%, #EBF7A2 99.54%);'
const strokeColorFn = (props: SegmentProps) =>
  props.strokeColor === 'dark'
    ? darkStroke
    : props.strokeColor === 'light'
    ? lightStroke
    : 'white'

interface ProgressIndicatorProps {
  icon: string
  value: number
  active?: boolean
  strokeColor?: StrokeColor
  useStrokeColor: boolean
  borderColor?: string
  startFirstAnimation?: boolean
  onComplete?: (result: 'shrinked' | 'growed') => void
}

interface BarProps {
  borderColor?: string
}

interface SegmentProps {
  value: number
  strokeColor?: string
  active?: boolean
}

interface BarImageProps {
  active?: boolean
}

const Bar = styled.div<BarProps>`
  flex-grow: 1;
  padding: 5px;
  align-items: center;
  border-radius: 11px;
  ${(props) => css`
    background: ${props.borderColor
      ? props.borderColor
      : 'rgba(255, 255, 255, 0.1)'};
  `}
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  justify-content: flex-start;

  @media only screen and (max-width: 600px) {
    padding: 4px;
    border-radius: 8px;
  }
`
const Segment = styled(motion.div)<SegmentProps>`
  height: 12px;
  border-radius: 6px;
  ${(props) => css`
    width: ${Math.round(props.value * 100)}%;
  `}
  min-width: 42px;
  ${(props) =>
    props.active &&
    `
    min-width: 50px;
  `}
  background: ${strokeColorFn};
  transition: width 1s ease;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform-origin: center center;
  position: relative;
  @media only screen and (max-width: 600px) {
    height: 8px;
    border-radius: 4px;
    min-width: 34px;
    ${(props) =>
      props.active &&
      `
      min-width: 41px;
    `}
  }
`

const BarImage = styled.div<BarImageProps>`
  width: 46px;
  height: 46px;
  right: 0;
  ${(props) =>
    props.active &&
    `
    width: 64px;
    height: 64px;
    right: -8px; 
  `}
  margin-left: calc(50% - 50vw);
  border-radius: 50%;
  position: absolute;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    width: 36px;
    height: 36px;
    ${(props) =>
      props.active &&
      `
      width: 52px;
      height: 52px;
    `}
  }
`

const Pulse = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
`

export const ProgressIndicator = ({
  value,
  icon,
  active,
  strokeColor,
  borderColor,
  startFirstAnimation,
  useStrokeColor,
  onComplete,
}: ProgressIndicatorProps) => {
  // force value between 0 and 1
  value = Math.min(1, Math.max(0, value))
  const [width, setWidth] = useState(`${Math.round(value * 100)}%`)
  const [scale, setScale] = useState(active ? 1.15 : 1)
  const [pulse, setPulse] = useState(false)

  const [initial, setInitial] = useState(true)
  const [initialValue, setInitialValue] = useState(-1)
  const [forcedStrokeColor, setForcedStrokeColor] = useState(false)

  useEffect(() => {
    if (!initial) {
      setWidth(`${Math.round(value * 100)}%`)
    }
  }, [value, initial])

  useEffect(() => {
    // show stroke color if value changed
    if (initialValue === -1) {
      setInitialValue(Math.round(value * 100))
    } else {
      setForcedStrokeColor(true)
    }
    // show stroke color if value did not changed but is at 100%
    if (value === 1) {
      setForcedStrokeColor(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (!initial) {
      setScale(active ? 1.15 : 1)
    }
  }, [active, initial])

  return (
    <Bar borderColor={borderColor}>
      <Segment
        onAnimationStart={() => {
          setPulse(false)
        }}
        onAnimationComplete={() => {
          setPulse(false)
        }}
        animate={{
          width,
        }}
        transition={{
          duration: 0.5,
        }}
        value={value}
        strokeColor={
          useStrokeColor && forcedStrokeColor ? strokeColor : 'white'
        }
        active={active}
      >
        <BarImage active={active}>
          <Pulse
            animate={
              pulse
                ? 'pulse'
                : {
                    scale,
                  }
            }
            variants={{
              pulse: {
                scale: [1.1, 1.15, 1.1],
                boxShadow: [
                  '0 0 0 0 rgba(255, 255, 255, 0.4)',
                  '0 0 0 2px rgba(255, 255, 255, 0.2)',
                  '0 0 0 2px rgba(255, 255, 255, 0)',
                ],
                transition: {
                  duration: 2,
                  repeat: Infinity,
                },
              },
            }}
            transition={{
              duration: 1.5,
            }}
            onAnimationComplete={() => {
              if (active && initial) {
                setPulse(true)
              }
              setInitial(false)
            }}
          />
          <img
            src={`data:image/svg+xml;base64,${btoa(icon)}`}
            alt={`Interest`}
          />
        </BarImage>
      </Segment>
    </Bar>
  )
}
