import React, { useContext } from 'react'
import { i18n } from '@lingui/core'
import { GameStateContext } from '../contexts'
import { Warning } from './Warning'
import { Row } from './Row'

export const EventExcludedRoles = () => {
  const { gameState, event } = useContext(GameStateContext)

  if (!event.excludedFromDecisionDesc) {
    return null
  }
  if (!event.excludedFromDecisionDesc[i18n.locale as 'en' | 'lo']) {
    return null
  }

  if (event.excludedFromDecisionPlayerId) {
    if (event.excludedFromDecisionPlayerId.length > 0) {
      if (
        !event.excludedFromDecisionPlayerId.every((playerId) =>
          gameState.roles.map((p) => p.id).includes(playerId)
        )
      ) {
        return null
      }
    }
  }

  return (
    <Row
      marginBottom={{ mobile: '30px', desktop: '60px' }}
      flexDirection="column"
    >
      <Warning playerIds={event.excludedFromDecisionPlayerId}>
        {event.excludedFromDecisionDesc![i18n.locale as 'en' | 'lo']}
      </Warning>
    </Row>
  )
}
