import React from 'react'
import styled from 'styled-components'
import { Span } from './Typography'

interface WarningProps {
  children: React.ReactNode
  playerIds?: string[]
}
export const Warning = ({ children, playerIds }: WarningProps) => {
  return (
    <Wrapper>
      <Inner>
        <Content>
          <Span color="#ff7956">{children}</Span>
        </Content>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #f3dbc4;
  border-radius: 5px;
  color: #ff7956;
  padding: 15px 20px;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  text-align: center;
`
