import React from 'react'
import styled from 'styled-components'

import * as theme from '../theme'
import { CheckBoxSmallActive, CheckBoxSmallInactive } from './icon/CheckBox'
import { ButtonFontStyle } from './Typography'

type ButtonSize = 'large' | 'small'
export type ButtonVariant =
  | 'positive'
  | 'negative'
  | 'special'
  | 'outline'
  | 'outlineLight'
  | 'info'
  | 'link'
const DEFAULT_VARIANT = 'positive'

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  textColor?: string
  textColorHover?: string
  textTransform?: string
  textWeight?: string
  underline?: boolean
}

const regularColorFromVariantProps = (props: ButtonProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return props.variant === 'link' ? ['transparent'] : (theme as any)[variant]
}
const lightColorFromVariantProps = (props: ButtonProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return variant !== 'outline' && variant !== 'outlineLight'
    ? (theme as any)[`${variant}Light`]
    : ['none']
}

const outlineColorFromVariantProps = (props: ButtonProps) => {
  return props.variant === 'outline'
    ? (theme as any)[`${props.variant}Color`]
    : props.variant === 'outlineLight'
    ? (theme as any)[`${props.variant}Color`]
    : ['none']
}

const textColorFromVariantProps = (props: ButtonProps) => {
  return props.variant === 'link' && props.textColor
    ? props.textColor
    : props.variant === 'link'
    ? (theme as any)[`textBlue`]
    : '#fff'
}

const textColorHover = (props: ButtonProps) => {
  return props.textColorHover && `&:hover {color: ${props.textColorHover};}`
}

const paddingByButtonSize = (props: ButtonProps) => {
  return props.size === 'large' ? '16px 24px' : '12px 40px'
}

export const Button = styled.button<ButtonProps>`
  outline: ${outlineColorFromVariantProps};
  margin: 0 8px;
  padding: ${paddingByButtonSize};
  border-radius: 100px;
  background-color: ${regularColorFromVariantProps};
  color: ${textColorFromVariantProps};
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  &:disabled {
    cursor: default;
    background-color: ${lightColorFromVariantProps};
  }
  ${textColorHover}
  ${(props) => ButtonFontStyle(props.variant)}
`

type CheckBoxButtonVariants = 'selected' | 'inactive'

interface CheckBoxButtonProps {
  children: React.ReactNode
  onClick: () => void
  variant?: CheckBoxButtonVariants
}

export const CheckBoxButton = ({
  children,
  onClick,
  variant,
}: CheckBoxButtonProps) => {
  return (
    <CheckBoxButtonWrap
      onClick={() => {
        onClick()
      }}
      variant={variant}
    >
      {variant === 'selected' ? (
        <CheckBoxSmallActive />
      ) : (
        <CheckBoxSmallInactive />
      )}
      <CheckBoxButtonContent>{children}</CheckBoxButtonContent>
    </CheckBoxButtonWrap>
  )
}

export const CheckBoxButtonWrap = styled.button<{
  variant?: CheckBoxButtonVariants
}>`
  ${ButtonFontStyle()}

  text-transform: initial;

  display: flex;
  align-items: center;
  padding: 9px 15px;
  margin: 0 8px;
  border-radius: 100px;

  cursor: pointer;
  outline: none;
  border: 0;
  -webkit-appearance: none;

  transition: background-color 0.2s, color 0.2s, transform 0.2s;

  ${(props) =>
    props.variant === 'inactive' || props.variant === 'selected'
      ? 'transform: scale(1) !important; cursor: initial;'
      : ''}

  ${(props) =>
    props.variant === 'inactive'
      ? `color: rgba(255,255,255,0.5);background-color: #3A415C;`
      : `color: #fff;background-color: #747882;`}


  margin-bottom: 15px;

  @media only screen and (min-width: 600px) {
    margin-bottom: 30px;
  }
`

export const CheckBoxButtonContent = styled.div`
  padding-left: 10px;
  text-align: left;
`
