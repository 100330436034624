import { GameEvent, GameEventEffect } from '../model'
import { INTERESTS } from './interests'

export const getMostImportantScoreTypes = (event: GameEvent) => {}

export const isHighlyAffected = (
  effect: GameEventEffect,
  interestId: string
) => {
  const totalEffect =
    Math.pow(effect.A[interestId], 2) + Math.pow(effect.B[interestId], 2)
  let higherValues = 0

  INTERESTS.forEach((interest) => {
    if (
      Math.pow(effect.A[interest.id], 2) + Math.pow(effect.B[interest.id], 2) >
      totalEffect
    ) {
      higherValues++
    }
  })
  return higherValues <= 1
}
