// Text colors
export const textLight = '#827D78'
export const textDark = '#454545'
export const textBlue = '#006F9E'

// Shape colors
export const positive = '#747885'
export const positiveLight = 'rgba(0, 109, 101, 0.2)'
export const negative = '#FF6740'
export const negativeLight = 'rgba(191, 18, 43, 0.2)'
export const special = '#FFFFFF'
export const specialLight = 'rgba(207, 150, 47, 0.2)'
export const outline = 'transparent'
export const outlineLight = 'transparent'
export const info = '#006F9E'
export const infoLight = 'rgba(0, 111, 158, 0.2)'

//Outline Color
export const outlineColor = '1px solid #454545'
export const outlineLightColor = '1px solid white'
