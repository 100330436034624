import {
  GLOBAL_SCORE_MALUS_THRESHOLD,
  INITIAL_EVENT_GROUP,
  INITIAL_GLOBAL_SCORE,
  MAX_EVENTS_COUNT,
} from '../config'
import { DecisionOption, GameEvent, GameState, Ranking, Role } from '../model'
import { INITIAL_EVENTS } from './gameEvents'
import { INTERESTS } from './interests'
import { ROLES } from './roles'

function getRandomInitialScore() {
  let scores = {}
  INTERESTS.forEach((interest) => {
    const value = Math.random() / 5 + 0.3 // values in [0.3, 0.5]
    scores = {
      ...scores,
      ...{ [interest.id]: value },
    }
  })
  return scores
}

const INITIAL_LOCKED_EVENT_IDS = INITIAL_EVENTS.filter(
  (e) => e.initiallyLocked
).map((e) => e.id)

function getInitialRoleBonus() {
  const roleBonus: { [id: string]: number } = {}
  ROLES.forEach((role) => {
    roleBonus[role.id] = 0
  })
  return roleBonus
}

const getInitialEventId = () => {
  let availableEvents = INITIAL_EVENTS
  availableEvents = availableEvents.filter((e) => !e.initiallyLocked)
  availableEvents = availableEvents.filter((e) =>
    e.groups.includes(INITIAL_EVENT_GROUP)
  )

  const initialEventId =
    availableEvents[Math.floor(Math.random() * availableEvents.length)].id

  return initialEventId
}

function getInitialTendencies() {
  let tendencies = {}
  INTERESTS.forEach((interest) => {
    tendencies = {
      ...tendencies,
      ...{ [interest.id]: 0 },
    }
  })
  return tendencies
}

// score ranges from 0-100 in 10 steps - see RankingPresenter
export const getRankings = (
  scores: { [interestId: string]: number },
  roleBonus: { [interestId: string]: number },
  roles: Role[]
): Ranking[] => {
  const playersAndScores = roles.map((role) => {
    const score =
      INTERESTS.map(
        (interest) => scores[interest.id] * role.interest[interest.id]
      ).reduce((a, b) => a + b, 0) + roleBonus[role.id]
    return {
      role,
      score,
    }
  })
  playersAndScores.sort((a, b) => b.score - a.score)

  const ranking = playersAndScores.map(({ role, score }, index) => {
    //score is calculated depending on highest score
    let roleScore = Math.round((score * 10) / playersAndScores[0].score) * 10
    if (roleScore < 0) roleScore = 0
    if (roleScore > 100) roleScore = 100
    return {
      role,
      score: roleScore,
      rank: index + 1,
    }
  })

  return ranking
}

const INITIAL_SCORES = getRandomInitialScore()

const INITIAL_ROLE_BONUS = getInitialRoleBonus()

export const INITIAL_GAME_STATE: GameState = {
  gameState: 'playing',
  currentEventId: getInitialEventId(),
  currentEventDisplayInfo: true,
  screen: 'intro',
  previousScreen: 'intro',
  pastDecisions: [],
  lockedEventIds: INITIAL_LOCKED_EVENT_IDS,
  scores: INITIAL_SCORES,
  roleBonus: INITIAL_ROLE_BONUS,
  tendencies: getInitialTendencies(),
  globalScore: INITIAL_GLOBAL_SCORE,
  roles: ROLES,
  rolesRanking: getRankings(INITIAL_SCORES, INITIAL_ROLE_BONUS, ROLES),
}

export const setRoles = (state: GameState, roles: Role[]): GameState => {
  const rolesRanking = getRankings(state.scores, state.roleBonus, roles)
  return { ...state, roles: roles, rolesRanking }
}

export const getFutureEvents = (state: GameState): GameEvent[] => {
  const { pastDecisions, lockedEventIds } = state
  const pastEventIds = pastDecisions.map((d) => d.eventId)
  const lastDecision = pastDecisions[pastDecisions.length - 1]

  let availableEvents = INITIAL_EVENTS

  // events that were already shown (mw 12.08.21)
  availableEvents = availableEvents.filter((e) => !pastEventIds.includes(e.id))

  // events that have not been unlocked by a event (mw 12.08.21)
  availableEvents = availableEvents.filter(
    (e) => !lockedEventIds.includes(e.id)
  )

  // only include events with the group set as following question group (mw 12.08.21)
  availableEvents = availableEvents.filter((e) =>
    lastDecision.followingQuestionGroups.some((g) => e.groups.includes(g))
  )

  return availableEvents
}

export const proceedToNextEvent = (state: GameState): GameState => {
  const futureEvents = getFutureEvents(state)
  if (!futureEvents.length) {
    throw new Error('No events left.')
  }
  const nextEventId =
    futureEvents[Math.floor(Math.random() * futureEvents.length)].id
  return { ...state, currentEventId: nextEventId }
}

export const processDecision = (
  state: GameState,
  event: GameEvent,
  decision: DecisionOption
): GameState => {
  const effects = event.effect[decision]
  const roleEffects = event.roleEffect[decision]
  const unlocks = event.unlocks[decision]
  const {
    tendencies: currentTendencies,
    scores: currentScores,
    roleBonus: currentRoleBonus,
    lockedEventIds: currentLockedEventIds,
    globalScore: currentGlobalScore,
  } = state

  const followingQuestionGroups =
    decision === 'B'
      ? event.followingQuestionGroupsOptionB
      : event.followingQuestionGroupsOptionA
  const lockedEventIds = currentLockedEventIds.filter(
    (id) => !unlocks.includes(id)
  )
  const pastDecisions = state.pastDecisions.concat([
    { eventId: event.id, decision, followingQuestionGroups },
  ])
  const scores = getNewScore(currentScores, effects, currentTendencies)
  const tendencies = getNewTendencies(currentTendencies, effects)
  const globalScore = getNewGlobalScore(
    currentGlobalScore,
    currentScores,
    scores
  )
  const roleBonus = getNewRoleBonus(currentRoleBonus, roleEffects)

  const rolesRanking = getRankings(scores, roleBonus, state.roles)

  return {
    ...state,
    pastDecisions,
    scores,
    roleBonus,
    tendencies,
    lockedEventIds,
    globalScore,
    rolesRanking,
  }
}

const getNewScore = (
  scores: { [interestId: string]: number },
  effects: { [interestId: string]: number },
  tendencies: { [interestId: string]: number }
): { [interestId: string]: number } => {
  const getNewScoreValue = (interestId: string): number => {
    const tendencyBonus =
      Math.sign(effects[interestId]) === Math.sign(tendencies[interestId])
        ? tendencies[interestId]
        : 0
    return scores[interestId] + effects[interestId] + tendencyBonus
  }

  let newScoreValues = {}
  INTERESTS.forEach((interest) => {
    newScoreValues = {
      ...newScoreValues,
      ...{ [interest.id]: getNewScoreValue(interest.id) },
    }
  })
  return newScoreValues
}

const getNewTendencies = (
  tendencies: { [interestId: string]: number },
  effects: { [interestId: string]: number }
): { [interestId: string]: number } => {
  const BASE_TENDENCY_BONUS = 0.03
  const TENDENCY_THRESHOLD = 0.03
  const TENDENCY_PROGRESSION = 0.02
  const TENDENCY_MAX = 0.1

  const getNewTendencyValue = (interestId: string): number => {
    const tendencySign = Math.sign(tendencies[interestId])
    const effectSign = Math.sign(effects[interestId])
    if (effectSign === 0) {
      // reset tendency
      return 0
    }
    if (effectSign === tendencySign) {
      // same tendency => progression
      const newTendency =
        tendencies[interestId] + tendencySign * TENDENCY_PROGRESSION
      return Math.abs(newTendency) > TENDENCY_MAX // cap at max
        ? tendencySign * TENDENCY_MAX
        : newTendency
    }
    // else: the tendency changes
    return Math.abs(effects[interestId]) > TENDENCY_THRESHOLD
      ? effectSign * BASE_TENDENCY_BONUS
      : 0
  }

  let newTendencies = {}
  INTERESTS.forEach((interest) => {
    newTendencies = {
      ...newTendencies,
      ...{ [interest.id]: getNewTendencyValue(interest.id) },
    }
  })
  return newTendencies
}

const getNewRoleBonus = (
  roleBonus: { [id: string]: number },
  roleEffect: { [id: string]: number }
): { [id: string]: number } => {
  const newRoleBonus: { [id: string]: number } = {}
  Object.keys(roleBonus).forEach((id) => {
    newRoleBonus[id] = roleBonus[id] + roleEffect[id]
  })
  return newRoleBonus
}

const getNewGlobalScore = (
  globalScore: number,
  previousScores: { [interestId: string]: number },
  scores: { [interestId: string]: number }
): number => {
  INTERESTS.forEach((interest) => {
    if (previousScores[interest.id] > scores[interest.id]) {
      globalScore -= Math.min(
        Math.max(GLOBAL_SCORE_MALUS_THRESHOLD - scores[interest.id], 0),
        0.1
      )
    }
  })

  return globalScore
}

export const gameIsOver = (gameState: GameState): boolean => {
  return gameState.pastDecisions.length >= MAX_EVENTS_COUNT
}
