import React from 'react'
import { Router } from '@reach/router'

import { Scenario } from './pages'
import { i18n } from '@lingui/core'
import { messages } from './locales/en/messages'
import { I18nProvider } from '@lingui/react'
import { HelmetProvider } from 'react-helmet-async'

i18n.load('en', messages)
i18n.activate('en')

export default function App() {
  return (
    <I18nProvider i18n={i18n}>
      <HelmetProvider>
        <Router>
          <Scenario path="/" />
        </Router>
      </HelmetProvider>
    </I18nProvider>
  )
}
