import React, { useContext, useEffect, useRef } from 'react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import ReactMarkdown from 'react-markdown'
import { GameStateContext, ScenariosContext } from '../../contexts'
import {
  AppLayout,
  B,
  Button,
  H4,
  Row,
  EventAffectedRoles,
  EventExcludedRoles,
  PageIntro,
  Span,
} from '../../facets'
import { EventScoresChart, EventDecision } from '../../controls'
import { DecisionOption } from '../../model'
import styled, { keyframes } from 'styled-components'

export const EventPresenter = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)

  const scenario = useContext(ScenariosContext)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!(scenario && event))
    return (
      <Trans id="scenario.error.occurred">Es ist ein Fehler aufgetreten!</Trans>
    )

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  const infoText = event.info[i18n.locale as 'en' | 'lo']

  if (!infoText && gameState.currentEventDisplayInfo) {
    dispatch({ type: 'showEventQuestion' })
  }

  const endGame = () => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: 'endGame',
      })
      resolve()
    })
  }

  return (
    <AppLayout
      headerVariant="inGame"
      bgVariant={event.noDecision ? 'gray' : 'darkBlue'}
    >
      <PageIntro>
        <B
          color={event.noDecision ? 'rgba(255, 255, 255, 0.6);' : '#B5B7BF'}
          center
        >
          {gameState.currentEventDisplayInfo && (
            <Trans id="event.intro.story.title">Story</Trans>
          )}
          {!decision && !gameState.currentEventDisplayInfo && (
            <Trans id="event.intro.event.title">Event</Trans>
          )}
          {decision && (
            <Trans id="event.intro.consequences.title">Consequences</Trans>
          )}
        </B>
      </PageIntro>
      {gameState.currentEventDisplayInfo ? (
        <>
          <H4 center>
            <ReactMarkdown>{infoText}</ReactMarkdown>
          </H4>
          <Row marginTop="4rem" justifyContent="center">
            <Button
              variant={'negative'}
              onClick={() => {
                dispatch({ type: 'showEventQuestion' })
              }}
            >
              <Trans id="event.info.ok">Ok</Trans>
            </Button>
          </Row>
        </>
      ) : (
        <>
          <H4 center>
            {!gameState.currentEventDisplayInfo && (
              <ReactMarkdown>
                {event.question[i18n.locale as 'en' | 'lo']}
              </ReactMarkdown>
            )}
          </H4>
          <EventScoresChartWrap>
            <EventScoresChart
              event={event}
              gameState={gameState}
            ></EventScoresChart>
          </EventScoresChartWrap>

          <EventDecision />

          {!decision && <EventExcludedRoles />}

          {event.noDecision && !decision && (
            <Row marginTop="3rem" justifyContent="center">
              <Button
                onClick={() => {
                  dispatch({
                    type: 'resolveEvent',
                    event,
                    decision: 'A' as DecisionOption,
                  })
                }}
                variant={'negative'}
              >
                {event.optionA[i18n.locale as 'en' | 'lo']}
              </Button>
            </Row>
          )}

          {decision && (
            <FadeInWrap>
              <EventAffectedRoles />
              <Span center>
                <ReactMarkdown>
                  {event.explanation[decision][i18n.locale as 'en' | 'lo']}
                </ReactMarkdown>
              </Span>
              <Row marginTop="3rem" justifyContent="center">
                <Button
                  variant={'negative'}
                  onClick={() => {
                    dispatch({
                      type: 'nextEvent',
                    })
                  }}
                >
                  <Trans id="event.next-event">Continue</Trans>
                </Button>
              </Row>
            </FadeInWrap>
          )}
        </>
      )}

      <Row marginTop="4rem" justifyContent="center">
        <Button
          variant={'link'}
          textColor="rgba(255,255,255,0.5)"
          textColorHover="rgba(255,255,255,1)"
          onClick={() => endGame()}
        >
          <Trans id="event.end-game">End Game</Trans>
        </Button>
      </Row>
    </AppLayout>
  )
}

const EventScoresChartWrap = styled.div`
  margin: 30px 0 50px;
  display: flex;
  flex-direction: column;
  background: repeating-linear-gradient(
        to bottom,
        transparent 0 4px,
        rgba(255, 255, 255, 0.2) 4px 8px
      )
      25%/2px 100% no-repeat,
    repeating-linear-gradient(
        to bottom,
        transparent 0 4px,
        rgba(255, 255, 255, 0.2) 4px 8px
      )
      50%/2px 100% no-repeat,
    repeating-linear-gradient(
        to bottom,
        transparent 0 4px,
        rgba(255, 255, 255, 0.2) 4px 8px
      )
      75%/2px 100% no-repeat;
`

const fadeIn = keyframes`
  from {
    transform: translate3d(0px, 180px, 0px);
    opacity: 0;
  }
  to {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
`

const FadeInWrap = styled.div`
  transform: translate3d(0px, 180px, 0px);
  opacity: 0;

  animation-name: ${fadeIn};
  animation-duration: 0.75s;
  animation-timing-function: ease-out;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`
