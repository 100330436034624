import React, { useContext, useEffect, useState } from 'react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import { AppLayout, B, Button, H6, Paragraph } from '../../facets'
import { CheckBoxActive, CheckBoxInactive } from '../../facets/icon/CheckBox'
import { GameStateContext } from '../../contexts'
import { ROLES } from '../../util.ts/roles'
import { GameModeratorAvatar } from '../../facets/icon/GameModaratorAvatar'

export const SelectRolesPresenter = () => {
  const availableRoles = ROLES
  const locale = i18n.locale as 'en' | 'lo'
  const { dispatch } = useContext(GameStateContext)

  // initial state - all player are selected (mw 10.08.21)
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    availableRoles.map((p) => p.id)
  )

  // save selected players in game-state (mw 10.08.21)
  useEffect(() => {
    dispatch({
      type: 'setRoles',
      roles: availableRoles.filter((p) => selectedRoles.includes(p.id)),
    })
  }, [availableRoles, selectedRoles, dispatch])

  // remove or add player to selection when clicking on a card (mw 10.08.21)
  const handleCardClick = (playerId: string): void => {
    const selection = selectedRoles

    if (selection.includes(playerId)) {
      selection.splice(selection.indexOf(playerId), 1)
    } else {
      selection.push(playerId)
    }

    setSelectedRoles([...selection])
  }

  return (
    <>
      <AppLayout bgVariant="lightGrey">
        <PageIntro>
          <B color="#B5B7BF" center>
            <Trans id="selectPlayer.intro.title">Rollen wählen</Trans>
          </B>
          <Paragraph>
            <Trans id="selectPlayer.intro.desc">
              Optimal spielt sich das Spiel, wenn alle 10 Rollen mind. einmal
              besetzt werden können. Es empfiehlt sich jedoch das Spiel nicht
              mit weniger als 7 Spieler zu spielen.
            </Trans>
          </Paragraph>
        </PageIntro>

        <PlayerCards>
          <PlayerCard bgVariant="orange" key={0}>
            <PlayerCardImage>
              <GameModeratorAvatar />
            </PlayerCardImage>
            <PlayerCardContent>
              <B color="#FF6740">
                <Trans id="select.roles.moderator.title">Spielleiter/In</Trans>
              </B>
              <H6>
                <Trans id="select.roles.moderator.description">
                  Neutrale Person, die durch das Spiel leitet
                </Trans>
              </H6>
            </PlayerCardContent>
          </PlayerCard>
          {availableRoles.map((role) => (
            <PlayerCard
              key={role.id}
              onClick={() => {
                handleCardClick(role.id)
              }}
            >
              <PlayerCardImage>
                <img
                  src={`data:image/svg+xml;base64,${btoa(role.avatar)}`}
                  alt={`Avatar ${role.name}`}
                />
              </PlayerCardImage>
              <PlayerCardContent>
                <B>{role.name[locale]}</B>
                <B color="#FF6740">{role.jobDesc[locale]}</B>
                <H6>{role.desc[locale]}</H6>
              </PlayerCardContent>
              <PlayerCardAction>
                {selectedRoles.includes(role.id) ? (
                  <CheckBoxActive />
                ) : (
                  <CheckBoxInactive />
                )}
              </PlayerCardAction>
            </PlayerCard>
          ))}
        </PlayerCards>
      </AppLayout>
      <PageActions>
        <PageActionsContent>
          {availableRoles
            .filter((p) => selectedRoles.includes(p.id))
            .map((role, i) => (
              <img
                key={i}
                src={`data:image/svg+xml;base64,${btoa(role.avatarSmall)}`}
                alt={`Avatar ${role.name}`}
                style={{ zIndex: 900 - i }}
              />
            ))}
        </PageActionsContent>
        <PageActionsButtons>
          <Button
            onClick={() => dispatch({ type: 'showManual' })}
            variant={'negative'}
          >
            <Trans id="language.start">Start</Trans>
          </Button>
        </PageActionsButtons>
      </PageActions>
    </>
  )
}

const PlayerCards = styled.div`
  padding-bottom: 100px;
`

const PageActions = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #eeede2;
  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`

const PageActionsContent = styled.div`
  padding-right: 5px;
  min-height: 30px;
  img {
    width: 25px;
    height: 25px;
    margin-right: -6px;
    border: 1px solid #eec8a2;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
  }
`

const PageActionsButtons = styled.div``

const PageIntro = styled.div`
  b,
  p {
    margin-bottom: 22px;
    display: block;
    text-align: center;
  }
`

const PlayerCard = styled.a<PlayerCardProps>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 18px 20px;
  cursor: pointer;
  border: 1px solid #ebebf0;
  border-radius: 3px;

  transition: 0.2s background-color, 0.2s border-color;
  ${(props) =>
    props.bgVariant === 'orange'
      ? 'background-color: #fff6ea;'
      : 'background-color: #ffffff;'}

  &:hover {
    background-color: #fff6ea;
    border-color: #f7dfb9;
  }
`

const PlayerCardImage = styled.div`
  img {
    border: 1px solid #dedde0;
    border-radius: 50%;
    background-color: #fff;
  }
`

const PlayerCardContent = styled.div`
  flex: 1;
  padding: 0 20px;
  b {
    display: block;
  }
  h6  {
    margin-top: 2px;
  }
`
export interface PlayerCardProps {
  bgVariant?: 'orange'
}

const PlayerCardAction = styled.div``
