import React from 'react'

export const CheckBoxActive = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0C5.82024 0 0 5.82024 0 13C0 20.1795 5.82024 26 13 26C20.1795 26 26 20.1795 26 13C26 5.82024 20.1795 0 13 0ZM19.1438 9.47556L12.6083 17.9336C12.4017 18.2012 12.0981 18.3488 11.7841 18.3736C11.3831 18.4297 10.9636 18.2794 10.7022 17.9362L6.84984 12.8816C6.46476 12.376 6.56298 11.6474 7.06853 11.262C7.57409 10.877 8.30267 10.9752 8.68805 11.4807L11.6604 15.381L17.3151 8.06289C17.7037 7.55993 18.4331 7.46633 18.9361 7.85518C19.439 8.24402 19.5324 8.9726 19.1438 9.47556Z"
      fill="#F7DFB9"
    />
  </svg>
)
export const CheckBoxInactive = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="#EBEBF0" />
  </svg>
)

export const CheckBoxSmallActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#A8D76E" />
    <circle
      cx="12"
      cy="12"
      r="11"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0839 16.6154C9.82673 16.6154 9.58032 16.5145 9.39869 16.3352L5.82243 12.8034C5.44396 12.4299 5.44396 11.8237 5.82243 11.4502C6.2009 11.0762 6.81416 11.0762 7.19263 11.4502L10.0839 14.305L16.8076 7.66497C17.1855 7.2912 17.7993 7.2912 18.1778 7.66497C18.5563 8.03846 18.5563 8.64464 18.1778 9.01813L10.7689 16.3352C10.5873 16.5145 10.3409 16.6154 10.0839 16.6154Z"
      fill="white"
    />
  </svg>
)
export const CheckBoxSmallInactive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
  </svg>
)
