import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  CoffeeBeanSvg,
  CoffeeBeanSvgMaskRight,
  CoffeeBeanSvgMaskLeft,
} from '../facets/icon/Header'

// in order to animate svg elements scale without cutting them off because
// they get bigger than the svg-view-box the svg is bigger than it's html
// wrapper. (mw 15.11.21)
const svgPadding = 20

const GlobalScore = ({
  nrOfCoffeeBeans,
  isRanking = false,
  lastNrOfCoffeeBeans = -1,
}: {
  nrOfCoffeeBeans: number
  isRanking?: boolean
  lastNrOfCoffeeBeans?: number
}) => {
  const [colorTheme, setColorTheme] = useState<any>(ColorTheme['gameHeader'])

  const [isAnimated, setIsAnimated] = useState<
    'preload' | 'initial' | 'animated'
  >('preload')

  useEffect(() => {
    if (nrOfCoffeeBeans > -1) {
      if (isRanking) {
        if (nrOfCoffeeBeans === 0) {
          setColorTheme(ColorTheme['negative'])
        } else if (nrOfCoffeeBeans === 5) {
          setColorTheme(ColorTheme['positive'])
        } else {
          setColorTheme(ColorTheme['neutral'])
        }
      } else {
        if (isAnimated === 'preload') {
          setIsAnimated('initial')
        } else {
          setIsAnimated('animated')
          if (nrOfCoffeeBeans < lastNrOfCoffeeBeans) {
            setColorTheme(ColorTheme['gameHeaderLoosing'])
            setTimeout(() => {
              setColorTheme(ColorTheme['gameHeader'])
            }, 3000)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nrOfCoffeeBeans])

  const renderCoffeeBeansBg = () => {
    const coffeeBeans = []

    for (let i = 1; i <= 5; i++) {
      const visible = i <= nrOfCoffeeBeans
      coffeeBeans.push(
        <React.Fragment key={`scorePointBg-${i}`}>
          <CoffeeBeanWrap className={visible ? 'visible' : 'invisible'}>
            <g className="circle-inner-wrap">
              <circle
                cx={svgPadding + i * 30 - 4}
                cy={svgPadding + 19}
                r={13}
              />
            </g>
          </CoffeeBeanWrap>
        </React.Fragment>
      )
    }

    return coffeeBeans
  }

  const renderCoffeeBeansFg = () => {
    const coffeeBeans = []

    for (let i = 1; i <= 5; i++) {
      const visible = i <= nrOfCoffeeBeans
      coffeeBeans.push(
        <React.Fragment key={`scorePointFg-${i}`}>
          <CoffeeBeanWrap className={visible ? 'visible' : 'invisible'}>
            <g className="coffee-bean-inner-wrap-right">
              <CoffeeBeanSvg
                x={svgPadding + i * 30 - 4}
                y={svgPadding + 19}
                mask={`url(#scorePointFgMaskRight)`}
              />
            </g>
            <g className="coffee-bean-inner-wrap-left">
              <CoffeeBeanSvg
                x={svgPadding + i * 30 - 4}
                y={svgPadding + 19}
                mask={`url(#scorePointFgMaskLeft)`}
              />
            </g>
          </CoffeeBeanWrap>
        </React.Fragment>
      )
    }

    return coffeeBeans
  }

  return (
    <Wrap bgGradient={colorTheme.gradient}>
      <GlobalScoreWrap
        viewBox={`0 0 ${174 + svgPadding * 2} ${38 + svgPadding * 2}`}
        xmlns="http://www.w3.org/2000/svg"
        borderColorActive={colorTheme.filledCircleBorder}
        borderColorInactive={colorTheme.emptyCircleBorder}
        bgGradient={colorTheme.bgGradient}
        className={isAnimated === 'animated' ? 'animated' : 'not_animated'}
      >
        <defs>
          <linearGradient id="bgGradient">
            <stop
              className="bgGradientStop1"
              offset="0%"
              stopColor="rgba(255,255,255,0.1)"
            />
            <stop
              className="bgGradientStop2"
              offset="50%"
              stopColor="rgba(255,255,255,0.1)"
            />
            <stop
              className="bgGradientStop3"
              offset="100%"
              stopColor="rgba(255,255,255,0.1)"
            />
          </linearGradient>
        </defs>
        <rect
          x={svgPadding}
          y={svgPadding}
          width="174"
          height="38"
          fill="url(#bgGradient)"
          rx="19"
        />
        <mask id={`scorePointFgMaskRight`}>
          <CoffeeBeanSvgMaskRight />
        </mask>
        <mask id={`scorePointFgMaskLeft`}>
          <CoffeeBeanSvgMaskLeft />
        </mask>
        {renderCoffeeBeansBg()}
        {renderCoffeeBeansFg()}
      </GlobalScoreWrap>
    </Wrap>
  )
}

export default React.memo(GlobalScore)

const ColorTheme = {
  positive: {
    filledCircleBorder: '#fff',
    emptyCircleBorder: '#fff',
    bgGradient: [
      'rgba(217, 227, 167, 0.5)',
      'rgba(227, 225, 82, 0.5)',
      'rgba(241, 234, 77, 0.5)',
    ],
  },
  negative: {
    filledCircleBorder: 'transparent',
    emptyCircleBorder: '#fff',
    bgGradient: [
      'rgba(140, 107, 122, 0.9)',
      'rgba(191, 134, 119, 0.9)',
      'rgba(255, 121, 86, 0.9)',
    ],
  },
  neutral: {
    filledCircleBorder: 'transparent',
    emptyCircleBorder: '#1A2038',
    bgGradient: ['#EBEBF0', '#EBEBF0', '#EBEBF0'],
  },
  gameHeader: {
    filledCircleBorder: '#fff',
    emptyCircleBorder: '#fff',
    bgGradient: [
      'rgba(255, 255, 255, 0.1)',
      'rgba(255, 255, 255, 0.1)',
      'rgba(255, 255, 255, 0.1)',
    ],
  },
  gameHeaderLoosing: {
    filledCircleBorder: '#fff',
    emptyCircleBorder: '#fff',
    bgGradient: [
      'rgba(255, 121, 86, 0.5)',
      'rgba(255, 121, 86, 0.5)',
      'rgba(255, 121, 86, 0.5)',
    ],
  },
}

const Wrap = styled.div<{ bgGradient: any }>`
  display: inline-flex;
  width: 174px;
`

const popInCoffeeBeanStep01 = keyframes`
    0% {opacity: 0; transform: scale(0.7);}
  100% {opacity: 1; transform: scale(1.75);}
`

const popInCoffeeBeanStep02 = keyframes`
    0% {opacity: 1; transform: scale(1.8);}
  100% {opacity: 1; transform: scale(1);}
`

const breakCoffeeBeanStep01Right = keyframes`
    0% {opacity: 1; transform: translate3d( 0px,  0px, 0px) rotate(  0deg);}
  100% {opacity: 1; transform: translate3d( 4px,  0px, 0px) rotate( 16deg);}
`

const breakCoffeeBeanStep02Right = keyframes`
    0% {opacity: 1; transform: translate3d( 4px,  0px, 0px) rotate( 16deg);}
  100% {opacity: 0; transform: translate3d( 4px, 19px, 0px) rotate( 16deg);}
`

const breakCoffeeBeanStep01Left = keyframes`
    0% {opacity: 1; transform: translate3d( 0px,  0px, 0px) rotate(  0deg);}
  100% {opacity: 1; transform: translate3d(-4px,  0px, 0px) rotate(-16deg);}
`

const breakCoffeeBeanStep02Left = keyframes`
    0% {opacity: 1; transform: translate3d(-4px,  0px, 0px) rotate(-16deg);}
  100% {opacity: 0; transform: translate3d(-4px, 15px, 0px) rotate(-16deg);}
`

const fadeInCircle = keyframes`
    0% {stroke-dasharray: 2px 2px;transform: scale(1);}
   25% {stroke-dasharray: 2px 2px;transform: scale(1);}
   50% {stroke-dasharray: 2px 1px;transform: scale(1.4);}
  100% {stroke-dasharray: 2px 0px;transform: scale(1);}
`

const fadeOutCircle = keyframes`
    0% {stroke-dasharray: 2px 0px;}
  100% {stroke-dasharray: 2px 2px;}
`

// animation durations in seconds
const timings = {
  colorChanges: 0.75,

  popInStep01: 0.75,
  popInStep01PauseAfter: 1.5,
  popInStep02: 0.3,

  breakStep01PauseBefore: 1.3,
  breakStep01: 0.3,
  breakStep01PauseAfter: 1,
  breakStep02: 0.3,
}

// calculate animation durations and delays:
// multiple css keyframes animation are started at the same time - in order
// to have multiple steps the delay of second animation is the duration of the
// first animation.

const colorChangeDur = timings.colorChanges

const popIn01Dur = timings.popInStep01
const popIn01Dly = 0

const popIn02Dur = timings.popInStep02
const popIn02Dly = timings.popInStep01 + timings.popInStep01PauseAfter

const break01Dur = timings.breakStep01
const break01Dly = timings.breakStep01PauseBefore

const break02Dur = timings.breakStep02
const break02Dly =
  timings.breakStep01PauseBefore +
  timings.breakStep01 +
  timings.breakStep01PauseAfter

const GlobalScoreWrap = styled.svg<{
  borderColorInactive: string
  borderColorActive: string
  bgGradient: any
}>`
  margin: -${svgPadding}px;

  circle {
    fill: transparent;
    stroke-width: 1px;
  }

  .bgGradientStop1 {
    stop-color: ${(props) => props.bgGradient[0]};
  }
  .bgGradientStop2 {
    stop-color: ${(props) => props.bgGradient[1]};
  }
  .bgGradientStop3 {
    stop-color: ${(props) => props.bgGradient[2]};
  }

  .visible circle {
    stroke: ${(props) => props.borderColorActive};
  }
  .invisible circle {
    stroke: ${(props) => props.borderColorInactive};
  }

  circle {
    transition: stroke ${colorChangeDur}s;
  }
  stop {
    transition: stop-color ${colorChangeDur}s;
  }

  .visible .coffee-bean-inner-wrap-right,
  .visible .coffee-bean-inner-wrap-left {
    animation-name: ${popInCoffeeBeanStep01}, ${popInCoffeeBeanStep02};
    animation-duration: ${popIn01Dur}s, ${popIn02Dur}s;
    animation-delay: ${popIn01Dly}s, ${popIn02Dly}s;
    animation-timing-function: cubic-bezier(0.1, 0.3, 0.78, 0.06),
      cubic-bezier(0.46, 0.16, 0.29, 1);
    animation-fill-mode: forwards, forwards;
  }
  .visible .circle-inner-wrap {
    animation: ${fadeInCircle} ${colorChangeDur}s ease-in-out forwards;
  }

  .invisible .coffee-bean-inner-wrap-right,
  .invisible .coffee-bean-inner-wrap-left {
    animation-name: ${breakCoffeeBeanStep01Right}, ${breakCoffeeBeanStep02Right};
    animation-duration: ${break01Dur}s, ${break02Dur}s;
    animation-delay: ${break01Dly}s, ${break02Dly}s;
    animation-timing-function: cubic-bezier(0.15, 0.99, 0.29, -0.81),
      cubic-bezier(0.46, 0.16, 0.29, 1);
    animation-fill-mode: forwards, forwards;
  }
  .invisible .coffee-bean-inner-wrap-left {
    animation-name: ${breakCoffeeBeanStep01Left}, ${breakCoffeeBeanStep02Left};
  }

  .invisible .circle-inner-wrap {
    animation: ${fadeOutCircle} ${colorChangeDur}s ease-in-out forwards
      ${break01Dly}s;
  }

  &.not_animated {
    circle,
    stop {
      transition-duration: 0s !important;
      transition-delay: 0s !important;
    }
    .coffee-bean-inner-wrap-right,
    .coffee-bean-inner-wrap-left,
    .circle-inner-wrap {
      animation-duration: 0s !important;
      animation-delay: 0s !important;
    }
  }
`

const CoffeeBeanWrap = styled.g`
  > .coffee-bean-inner-wrap-right,
  > .coffee-bean-inner-wrap-left,
  > .circle-inner-wrap {
    transform-origin: center;
    transform-box: fill-box;
  }
`
