import { useContext, useEffect, useState } from 'react'
import { INITIAL_GLOBAL_SCORE } from '../config'
import { GameStateContext } from '../contexts'

export const useScore = () => {
  const { gameState } = useContext(GameStateContext)
  const { globalScore } = gameState
  const [nrOfCoffeeBeans, setNrOfCoffeeBeans] = useState(-1)
  const [lastNrOfCoffeeBeans, setLastNrOfCoffeeBeans] = useState(5)

  useEffect(() => {
    let newScore = Math.round(((globalScore / INITIAL_GLOBAL_SCORE) * 100) / 20)
    newScore = Math.min(newScore, 5)
    newScore = Math.max(newScore, 0)

    setLastNrOfCoffeeBeans(nrOfCoffeeBeans)
    setNrOfCoffeeBeans(newScore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalScore])

  return { nrOfCoffeeBeans, lastNrOfCoffeeBeans }
}
