import { GameEvent } from '../model'
import { getTranslated } from './helpers'
import { INTERESTS } from './interests'
import { ROLES } from './roles'

const eventsData = require('../static/events.json')

export const INITIAL_EVENTS: GameEvent[] = eventsData.map(
  (e: { [key: string]: string }): GameEvent => {
    function getInterestEffects(orientation: 'A' | 'B') {
      let interestEffects = {}
      INTERESTS.forEach((interest) => {
        let value = parseEffectValue(e[`effect${orientation}_${interest.id}`])
        value = value ? value + randomVariation(0.04) : 0

        interestEffects = {
          ...interestEffects,
          ...{ [interest.id]: value },
        }
      })
      return interestEffects
    }

    function getRoleEffects(orientation: 'A' | 'B') {
      const roleEffects: { [id: string]: number } = {}
      ROLES.forEach((role) => {
        const value = parseEffectValue(e[`roleEffect${orientation}_${role.id}`])
        roleEffects[role.id] = value ? value + randomVariation(0.04) : 0
      })
      return roleEffects
    }

    return {
      id: parseInt(e.id, 10),
      question: getTranslated(e, 'question'),
      optionA: getTranslated(e, 'optionA'),
      optionB: getTranslated(e, 'optionB'),
      info: getTranslated(e, 'info'),
      explanation: {
        A: getTranslated(e, 'explanationA'),
        B: getTranslated(e, 'explanationB'),
      },
      effect: {
        A: getInterestEffects('A'),
        B: getInterestEffects('B'),
      },
      roleEffect: {
        A: getRoleEffects('A'),
        B: getRoleEffects('B'),
      },
      noDecision: e.noDecision ? true : false,
      initiallyLocked: e.isLocked ? true : false,
      unlocks: {
        A: e.aUnlocks.split(' ').map((id) => parseInt(id, 10)),
        B: e.bUnlocks.split(' ').map((id) => parseInt(id, 10)),
      },
      excludedFromDecisionDesc: getTranslated(e, 'excludedFromDecisionDesc'),
      excludedFromDecisionPlayerId: e.excludedFromDecisionPlayerId
        .split(',')
        .map((p) => p.trim()),
      groups: e.groups.split(',').map((g) => g.trim()),
      followingQuestionGroupsOptionA: e.followingQuestionGroupsOptionA
        .split(',')
        .map((g) => g.trim()),
      followingQuestionGroupsOptionB: e.followingQuestionGroupsOptionB
        .split(',')
        .map((g) => g.trim()),
    }
  }
)

function parseEffectValue(value: string): number {
  return (parseInt(value, 10) || 0) / 100
}

function randomVariation(range: number): number {
  return Math.random() * 2 * range - range
}
